import { DocumentMeta } from './meta';
import type { PaymentStatus } from './static/types';
import { sum } from './utils';
import { Waterfall } from './waterfall/waterfall';

export interface Expense {
  _meta?: DocumentMeta;
  id: string;
  date: Date;
  /** The contract linked to this expense */
  contractId: string;
  rightholderId: string;
  titleId: string; // equals waterfallId
  status: PaymentStatus;
  price: number;
  version: Record<string, { hidden?: true, price: number }>;
  typeId: string;
  nature: string;
  capped: boolean;
}

export function createExpense(params: Partial<Expense> = {}): Expense {
  return {
    id: '',
    contractId: '',
    rightholderId: '',
    titleId: '',
    status: 'pending',
    price: 0,
    version: {},
    date: new Date(),
    typeId: '',
    nature: '',
    capped: false,
    ...params,
  };
}

interface ExpenseCap {
  default: number;
  version: Record<string, number>;
}

export interface ExpenseType {
  id: string;
  name: string;
  contractId: string;
  cap: ExpenseCap;
}

export function createExpenseType(params: Partial<ExpenseType> = {}): ExpenseType {
  return {
    id: '',
    name: '',
    contractId: '',
    ...params,
    cap: createExpenseCap(params.cap),
  };
}

function createExpenseCap(params: Partial<ExpenseCap> = {}): ExpenseCap {
  return {
    default: params.default ?? 0,
    version: params.version ?? {},
  };
}

export function expensesPrice(_expenses: Partial<Expense>[], typeId: string, cap = 0): number {
  const expenses = _expenses.filter(e => e.typeId === typeId);

  let capped = 0;
  let uncapped = 0;

  if (cap > 0) {
    capped = Math.min(sum(expenses.filter(e => e.capped).map(e => e.price)), cap);
    uncapped = sum(expenses.filter(e => !e.capped).map(e => e.price));
  } else {
    uncapped = sum(expenses.map(e => e.price));
  }

  return capped + uncapped;
}

export function expenseTypeCap(expenseType: ExpenseType, versionId?: string): number {
  if (!expenseType) return undefined;
  if (!versionId) return expenseType.cap.default;
  return expenseType.cap.version[versionId] ?? expenseType.cap.default;
}

export function isExpenseTypeCapped(expenseType: ExpenseType, versionId?: string): boolean {
  if (!expenseType) return false;
  return expenseTypeCap(expenseType, versionId) > 0;
}

export function getAllExpenseTypes(waterfall: Waterfall) {
  return Object.values(waterfall.expenseTypes).flat();
}
